<template>
  <div class="w-100 mt-2" style="margin-left: 50px; margin-right: 50px;" v-show="amReady">
    <!--<div class="dg-module-container text-capitalize mb-2" v-if="formHeader">
      <h5>{{ this.getFormTitle }}</h5>
    </div>-->

    <el-row style="min-width: 1290px;width: 100%;">
      <!-- Section 1 -->
      <el-col style="min-width: 1000px;width:100%; max-width: calc( 100% - 290px)">
        <h5 class="text-center" style="margin-bottom: 5px;">
          Client's Page
        </h5>

        <el-row v-if="amReady">
          <business-section :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode" :provinceId="selectedProvince"
                            @amUpdated=" (p) => {businessSectionUpdated(p)}"></business-section>
        </el-row>

        <el-row style="margin-top: 30px" v-if="amReady">
          <tax-section :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode" :has_direct_deposit="selectedDirectDeposit" :has_wsib="selectedWSIB"
                       :provinceId="selectedProvince"
                       @amUpdated=" (p) => {taxSectionUpdated(p)}"
          ></tax-section>
        </el-row>

        <el-row style="margin-top: 30px" v-if="amReady">
          <control-section  :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode" :has_direct_deposit="selectedDirectDeposit"
                           @amUpdated=" (p) => {selectedDirectDeposit = p.val}"
                           class="m-0"></control-section>
        </el-row>

        <el-row v-if="$services['user'].isRoot() || $xBundle['CURRENT_USER_PROFILE'].profile_id === 'ADMIN'" class="mb-4 form-section no-radius" :class="$store.state['App-UiColorSchema-api'].currentSchemaClasses">
          <!--<el-col :class="$langConfig.cssClass" :span="12" class="">
            <label class="error-field-validate-message text-capitalize"
                   v-show="!this.validData">{{
                (requestErrorMessage) ? requestErrorMessage : $ml.get('fields_do_not_match_the_required_criteria')
              }}</label>
          </el-col>-->
          <el-col :span="24" class="text-center">
            <dg-confirm-button
                :loading="requestOn"
                :onConfirm="moduleDeleteAction"
                :translate="false"
                confirmButtonType="danger"
                fieldSize="mini"
                icon=""
                popIcon="el-icon-delete"
                style="margin-right: 10px"
                text="remove"
                title="sure to removing this record ?"
                type="danger"
                v-if="editMode"></dg-confirm-button>

            <!--<dg-button :click="cancelEditable" class="mr-2" fieldSize="mini" icon="el-icon-close" text="cancel"
                       type="warning" v-if="editable && editMode"></dg-button>

            <dg-button :click="changeEditable" fieldSize="mini" icon="el-icon-edit" text="edit" type="success"
                       v-if="!editable && editMode"></dg-button>-->

            <el-alert
                :closable="false"
                :title="noticeableAlertTitle"
                :type="noticeableAlertType"
                center
                class="mb-3"
                show-icon
                v-if="noticeableAlertVisibility">
            </el-alert>
            <div class="text-success" style="display: inline-block;float: left;margin-left: 10px;">
              Active: {{ countOfActiveClients }}
            </div>
            <el-button @click="clearForm" type="primary">New</el-button>
            <el-button @click="startCreation" type="success">Save</el-button>
            <el-button @click="deleteCurrentClient" type="danger">Delete</el-button>
            <el-button @click="cancelChanges" type="info">Cancel</el-button>

            <el-button @click="goToDashboard" style="margin-left: 10px;">Exit</el-button>
            <div class="text-secondary" style="display: inline-block;float: right; margin-right: 10px;">
              <div style="text-align: right">
                <span class="text-primary">Pending: {{ countOfPendingClients }}</span><br>
                Closed: {{ countOfClosedClients }}
              </div>
            </div>


            <!--<dg-button :click="createModule" :icon="actionButtonIcon" :loading="requestOn" :text="actionButtonLabel"
                       :translate="false"
                       :type="actionButtonType" fieldSize="mini"
                       v-if="editable"></dg-button>-->

            <!--<dg-confirm-button
                :icon="actionButtonIcon"
                :loading="requestOn"
                :onConfirm="createModule"
                :text="actionButtonLabel"
                :translate="false"
                :type="actionButtonType"
                fieldSize="mini"
                v-if="editable"></dg-confirm-button>-->
          </el-col>
        </el-row>
      </el-col>
      <el-col style="width: 280px;margin-left: 10px; margin-top: 28px;">
        <list-fragment :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode" v-if="listViewReady"></list-fragment>
      </el-col>
      <!-- Section 2 End -->
    </el-row>

  </div>
</template>

<script>
import module from './../index.js'


export default {
  components: {
    'list-fragment': () => import('./list-fragment/View'),
    // 'audit-section': () => import('./AuditSection'),
    'control-section': () => import('./ControlSection.vue'),
    // 'payroll-section': () => import('./PayrollSection'),
    // 'wsib-section': () => import('./WSIBSection'),
    // 'direct-deposit-section': () => import('./DirectDepsitSection'),
    'business-section': () => import('./BusinessSection'),
    // 'head-office-section': () => import('./HeadOfficeSection'),
    // 'location-section': () => import('./LocationSection'),
    // 'contact-section': () => import('./ContactSection'),
    'tax-section': () => import('./TaxSection'),
    // 'phone-number': () => import('@/components/phone-number/View')
  },/*
  watch: {
    'dataModel': {
      handler(val){
        console.log('watch', val)
      },
      deep: true
    }
  },*/
  beforeMount() {
    // $xapi.getAccountingClientStatistices()
    /*$store.state['App-User-api'].usersByProfiles['ADMIN'] = []
    $store.state['App-User-api'].usersByProfiles['BOOKKEEPER'] = []
    $store.state['App-User-api'].usersByProfiles['PAYROLL'] = []*/
    if ($services['user'].isRoot() || $xBundle['CURRENT_USER_PROFILE'].profile_id === 'ADMIN') {
      this.ctrlsDisabled = false
    }
    this.amReady = false
    this.getFormData()
  },
  data() {
    return {
      currentClientId: null,
      countOfPendingClients: '',
      countOfActiveClients: '',
      countOfClosedClients: '',
      noticeableAlertType: 'info',
      noticeableAlertTitle: '',
      noticeableAlertVisibility: false,
      clientDataToLoad: null,
      clientLoaded: false,
      dataModel: {},
      selectedWSIB: null,
      selectedDirectDeposit: null,
      selectedPayroll: null,
      selectedProvince: null,
      module: {...module},
      control: this,
      amReady: false,
      listViewReady: false,
      requestOn: false,
      editable: true,
      ctrlsDisabled: true,
      requestErrorMessage: null
    }
  },
  methods: {
    checkDuplicateFields(directCheck, callback = false) {
      this.clearFieldError('business_number')
      this.clearFieldError('business_number_code')

      let newVal = this.dataModel.business_number + " " + this.dataModel.business_number_code
      if (this.originalNumber !== newVal)
        if (this.editMode) {
          if (!this.editable) return
          if (this.originalNumber !== newVal) this.callValidateApi("business_number__complete", newVal, directCheck, callback)
        } else {
          this.callValidateApi("business_number__complete", newVal, directCheck, callback)
        }
    },
    callValidateApi(name, val, directCheck, callback = false) {
      clearTimeout(this.checkDublicateFieldsTimeout)
      if (directCheck) {
        let data = {}
        data[name] = val
        let id = (this.clientLoaded)? this.dataModel.id : ''
        $xapi.accountingClientCheckDuplicateFields({id,  data}).then(r => {
          this.setfieldsErrorMessage(r.data, callback)
        })
      } else {
        this.checkDublicateFieldsTimeout = setTimeout(() => {
          let data = {}
          data[name] = val
          let id = (this.clientLoaded)? this.dataModel.id : ''
          $xapi.accountingClientCheckDuplicateFields({id,  data}).then(r => {
            this.setfieldsErrorMessage(r.data, callback)
          })
        }, 200)
      }
    },
    getUsersObjectFromValue(v, type) {
      let op = $store.state['App-User-api'].usersByProfiles[type];
      for (let x in op) {
        if (op[x].value == v) return op[x]
      }
      return null
    },
    closeNoticeableAlert(time = 3000) {
      setTimeout(() => {
        this.noticeableAlertVisibility = false
      }, time)
    },
    setNoticeableAlert(props) {
      this.noticeableAlertTitle = props.title
      this.noticeableAlertType = props.type
      this.noticeableAlertVisibility = true
    },
    goToDashboard() {
      $router.push({name: 'portal-cpanel->dashboard'})
    },
    cancelChanges() {
      $services['qDialog'].open('Changes', 'Are you sure you want to cancel changes ?', () => {
        this.amReady = false
        $vue.nextTick(() => {
          this.getFormData()
        })
      })
    },
    deleteCurrentClient() {
      $services['qDialog'].open('Delete', 'Are you sure you want to delete this client ?', () => {
        let clientId = $router.currentRoute.params.clientId
        if (clientId) {
          this.removeClient({id: clientId}).then(r => {
            $services['notify']['success']('Client Removed')
          }).catch(e => {
            $services['notify']['success']('Error Removing')
          })
          this.clearFormAction()
        } else {
          alert("There is not client id")
        }
      })
    },
    clearFormAction() {
      $router.push({name: 'module->Accounting->Client->create'})
      this.amReady = false
      $vue.nextTick(() => {
        this.amReady = true
      })
    },
    clearForm() {
      $services['qDialog'].open('Attention', 'you want to create a new Client ?', () => {
        this.clearFormAction()
      })
    },
    businessSectionUpdated(p) {
      if (p.field == 'business_province_of_operation_id') this.selectedProvince = p.val
      this[p.field] = p.val
    },
    taxSectionUpdated(p) {
      this[p.field] = p.val
    },
    logData() {
      //this.bindController()
      //console.log('dataModel', this.dataModel)
    },
    getFormData() {
      let clientId = $router.currentRoute.params.clientId
      let promises = []
      promises.push(this.getUsersByProfileDropdownList({profileId: 'ADMIN'}))
      promises.push(this.getUsersByProfileDropdownList({profileId: 'BOOKKEEPER'}))
      promises.push(this.getUsersByProfileDropdownList({profileId: 'PAYROLL'}))
      promises.push(this.getBusinessTypesDropdown())
      //promises.push(this.getBusinessLegalStructureDropdown())
      promises.push(this.getEntityDropDownList().then())
      promises.push($xapi.getAccountingReportingPeriodDropdown())
      $xapi.getAccountingClientStatistics().then(r => {
        this.countOfActiveClients = r.active
        this.countOfClosedClients = r.closed
        this.countOfPendingClients = r.pending
      })


      if (clientId) {
        this.clientLoaded = true
        promises.push($xapi.getAccountingClient({id: clientId}).then((r) => {
          if (r == '') {
            this.clearFormAction()
          } else {
            this.currentClientId = r.id
            this.dataModel = r
            this.clientDataToLoad = r
          }
        }))
      } else {
        this.clientLoaded = false
        this.clientDataToLoad = null
      }

      Promise.all(promises).then((values) => {
        this.amReady = true

        this.listViewReady = true
        if (this.clientDataToLoad) {
          //this.setModelData(this.clientDataToLoad)
        }
      })
    },
    ...$mapActions('Accounting-Client-api', {
      userDataAuthFields: 'userDataAuthFields',
      getEntityDropDownList: 'getDropDownList',
      removeClient: 'remove',
    }),
    ...$mapActions('App-User-api', {
      getUsersByProfileDropdownList: 'getUsersByProfileDropdownList',
    }),
    ...$mapActions('Business-Type-api', {
      getBusinessTypesDropdown: 'getDropDownList',
    }),
    ...$mapActions('Business-LegalStructure-api', {
      getBusinessLegalStructureDropdown: 'getDropDownList',
    }),
    async startCreation () {
      this.validData = true
      this.checkDuplicateFields(true, this.createModule)
    },
    async createModule() {
      if (this.validData == false) return
      this.validateFields().then(async result => {

        if (!result) {
          this.requestErrorMessage = null;
          return
        }

        if (!this.validData) return

        let inUpdateMode = this.editMode || this.clientLoaded

        this.requestOn = true
        let context = this;
        let apiMethod = (inUpdateMode) ? 'update' : 'create';
        let apiData = {...this.dataModel}
        if (apiData.hasOwnProperty('account_person_in_charge_user_id')) {
          apiData['account_person_in_charge_user_id'] = this.getUsersObjectFromValue(apiData['account_person_in_charge_user_id'], 'ADMIN')
        }

        if (apiData.hasOwnProperty('account_bookkeeper_user_id')) {
          apiData['account_bookkeeper_user_id'] = this.getUsersObjectFromValue(apiData['account_bookkeeper_user_id'], 'BOOKKEEPER')
        }

        if (apiData.hasOwnProperty('account_payroll_user_id')) {
          apiData['account_payroll_user_id'] = this.getUsersObjectFromValue(apiData['account_payroll_user_id'], 'PAYROLL')
        }

        let apiParams = {data: apiData};


        if (inUpdateMode) apiParams.id = this.clientDataToLoad.id;

        /* Images Handleing - removing files they are handled alone
        delete apiParams.data.file
        delete apiParams.data.picture
        delete apiParams.data.gallery

        if (apiMethod == 'update') {
            await this.uploadFile(this.dataToBind.id, 'file', 'updateFile') // Updating File
            await this.uploadFile(this.dataToBind.id, 'picture', 'updateImage') // Updating Picture
            await this.uploadGallery(this.dataToBind.id) // Updating Gallery
        }*/

        this.$store.dispatch('Accounting-Client-api/' + apiMethod, apiParams).then(async response => {
          let entity = response.data
          this.listViewReady = false
          this.setNoticeableAlert({
            title: 'Client ' + ((apiMethod == 'update') ? 'Updated' : 'Created'),
            type: 'success'
          })

          $services['notify']['view']({
            type: 'success',
            title: 'Client ' + ((apiMethod == 'update') ? 'Updated' : 'Created'),
            position: 'topRight',
          })

          /* if (apiMethod == 'create') {
             await this.uploadFile(entity.id, 'file', 'updateFile') // Updating File
             await this.uploadFile(entity.id, 'picture', 'updateImage') // Updating Picture
             await this.uploadGallery(entity.id) // Updating Gallery
          } */

          $vue.nextTick(() => {
            context.listViewReady = true
            $router.push({name: 'module->Accounting->Client->view', params: {clientId: entity.id}})
            /*if (inUpdateMode) {
              if (context.onEditCallback) context.onEditCallback(response.data)
            } else {
              if (context.onCreateCallback) context.onCreateCallback(response.data)
            }*/
          })

        }).catch(error => {
          console.log('catch error', error)
          this.setNoticeableAlert({
            title: 'Client has not been updated',
            type: 'warning'
          })

          this.validData = false
          if (error.response.data.errorMessage) {
            $store.state['App-App-state'].generalDialogStatus = true
            $store.state['App-App-state'].generalDialogMessage = error.response.data.errorMessage
            $store.state['App-App-state'].generalDialogTitle = "Attention"

            this.requestErrorMessage = error.response.data.errorMessage
          } else {
            this.setfieldsErrorMessage({
              fieldsErrors: error.response.data
            });
          }
        }).finally(() => {
          this.requestOn = false
          this.closeNoticeableAlert()
        })
      })
    }
  },
  mixins: [$mixins['fieldController'], $mixins['moduleForm']],
}
</script>
